const _TEMPLATES = [
  {
    title: "Autorización de Cirugía/Procedimiento",
    text: `La persona responsable del paciente Sr(a) ______________
              autoriza al Dr(a) ________________ y a quien éste designe, a
              realizar la cirugía/procedimiento
              ________________________________, efectuar la sedación o
              anestesia general y/o regional que sea necesaria y a todo otro
              procedimiento intraquirúrgico, destinado a procurar salvaguardar
              la vida de la mascota cuyos datos han sido detallados
              precedentemente, y procurar mejorar y/o recuperar la salud del
              mismo. Asimismo, deja constancia y acepta en forma irrevocable,
              que le han sido explicados y conoce los riesgos que implican
              para la vida de la mascota el sometimiento a dicho procedimiento
              quirúrgico y anestésico, los resultados esperados, las posibles
              complicaciones, así como eventuales secuelas derivadas de la
              sana práctica médica. A someterse a las indicaciones,
              tratamientos tanto médicos como quirúgicos, prácticas y
              controles que el personal médico veterinario considere
              convenientes. Liberar a los médicos veterinarios intervinientes
              y a sus ayudantes de toda responsabilidad a la práctica aludida.
              A realizar el pago total de los procedimientos. A retirar a la
              mascota y sus pertenencias en los tiempos estipulados por los
              profesionales actuantes, no siendo así, los gastos relacionados
              al cuidado adicional de la mascota serán de exclusivo cargo y
              responsabilidad del abajo firmante.`,
    signatureText: `Nombre Responsable`,
  },
  {
    title: "Autorización Sedación/Anestesia",
    text: `La persona responsable del paciente Sr(a) ______________ autoriza al Dr(a) ________________ y a quien éste designe, a realizar la sedación y/o anestesia general y/o regional que sea necesaria para el procedimiento ________________________________, destinado a procurar salvaguardar la vida de la mascota cuyos datos han sido detallados precedentemente, y procurar mejorar y/o recuperar la salud del mismo. 

Asimismo, deja constancia y acepta en forma irrevocable, que le han sido explicados y conoce los riesgos que implican para la vida de la mascota el sometimiento a dicho procedimiento, los resultados esperados, las posibles complicaciones, así como eventuales secuelas derivadas de la sana práctica médica. Liberar a los médicos veterinarios intervinientes y a sus ayudantes de toda responsabilidad a la práctica aludida. A realizar el pago total de los procedimientos. A retirar a la mascota y sus pertenencias en los tiempos estipulados por los profesionales actuantes, no siendo así, los gastos relacionados al cuidado adicional de la mascota serán de exclusivo cargo y responsabilidad del abajo firmante.



Certifica con su firma que ha leído y comprendido la presente autorización, prestando su consentimiento.
`,
    signatureText: `Nombre Responsable`,
  },
  {
    title: "Autorización de Eutanasia",
    text: `La persona responsable del paciente Sr(a) ________________ autoriza al Dr(a) _________________ y a quien éste designe, a realizar la Eutanasia de la mascota cuyos datos han sido detallados precedentemente, de conformidad con la técnica profesional habitual. 

Asimismo, deja constancia y acepta en forma irrevocable, que ha sido informado de que el diagnóstico consiste en _________________________, lo cual justifica plenamente la medida a adoptarse. Manifiesta que ha recibido y entendido la información sobre el procedimiento terapéutico al que voluntariamente autoriza que se someta. 

Certifica con su firma que ha leído y comprendido la presente autorización, prestando su consentimiento.
`,
    signatureText: `Nombre Responsable`,
  },
  {
    title: "Autorización de Hospitalización",
    text: `La persona responsable del paciente Sr(a) ______________ autoriza al Dr(a) ________________ y a quien éste designe, a hospitalizar por los días que requiere el tratamiento de ________________________________, destinado a procurar salvaguardar la vida de la mascota cuyos datos han sido detallados precedentemente, y procurar mejorar y/o recuperar la salud del mismo. 

Asimismo, deja constancia y acepta en forma irrevocable, que le han sido explicados y conoce los riesgos que implican para la vida de la mascota el sometimiento a dicho tratamiento, los resultados esperados, las posibles complicaciones, así como eventuales secuelas derivadas de la sana práctica médica. A someterse a las indicaciones, tratamientos tanto médicos como quirúgicos, prácticas y controles que el personal médico veterinario considere convenientes. Liberar a los médicos veterinarios intervinientes y a sus ayudantes de toda responsabilidad a la práctica aludida. A realizar el pago total de los procedimientos. A retirar a la mascota y sus pertenencias en los tiempos estipulados por los profesionales actuantes, no siendo así, los gastos relacionados al cuidado adicional de la mascota serán de exclusivo cargo y responsabilidad del abajo firmante.



Certifica con su firma que ha leído y comprendido la presente autorización, prestando su consentimiento.
`,
    signatureText: `Nombre Responsable`,
  },
  {
    title: "Retiro sin Alta Médica",
    text: `El presente documento es para certificar que Yo ________________, Rut _______________, como tutor responsable del paciente cuyos datos han sido detallados precedentemente, RETIRO VOLUNTARIAMENTE a mi mascota, pese a las indicaciones del médico tratante. 

Dejo CONSTANCIA que estoy en pleno conocimiento del riesgo que significa abandonar la clínica.

Declaro libre de toda responsabilidad a la clínica, al Médico tratante y sus ayudantes, de las consecuencias posteriores.

Certifica con su firma que ha leído y comprendido el presente documento, prestando su consentimiento.
`,
    signatureText: `Nombre Responsable`,
  },
  {
    title: "Certificado de Salud",
    text: `El médico veterinario que suscribe certifica que la mascota cuyos datos han sido detallados precedentemente se encuentra clínicamente sano al examen físico, sin presentar tumoraciones, heridas frescas o en proceso de cicatrización, ni signo alguno de enfermedades infectocontagiosas, cuarentenables o transmisibles, ni presencia de parásitos externos y ha sido tratado contra estos últimos.`,
    signatureText: `Nombre Doctor`,
  },
];

function TemplatesPage() {
  return (
    <div className="container mx-auto py-10 px-10 lg:px-56 flex flex-col gap-4">
      <section>
        <div>
          <img src="logo.png" alt="Veti Logo" className="mx-auto" />
        </div>
        <h1 className="text-3xl text-gray-700 my-7 mt-10 font-bold lg:text-4xl text-center">
          Templates Veti.app
        </h1>
        <p className="text-xl text-gray-700 my-7 font-bold lg:text-2xl">
          Indíce
        </p>
        <nav>
          <ul style={{ listStyleType: "disc" }}>
            {_TEMPLATES.map((template, idx) => {
              return (
                <li
                  key={idx + 1}
                  className="text-gray-500 my-6 text-lg lg:text-base text-justify hover:text-blue-500"
                >
                  <a
                    onClick={() => {
                      const element = document.getElementById(
                        `template-${idx + 1}`
                      );
                      element?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    {template.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
      </section>
      <section>
        {_TEMPLATES.map((template, idx) => {
          return (
            <div key={idx + 1} id={`template-${idx + 1}`}>
              <h2 className="text-2xl text-gray-700 my-7 mt-10 font-bold lg:text-2xl text-center">
                {template.title}
              </h2>
              <p>{template.text}</p>
              <p className="flex flex-col justify-end items-end pt-10">
                ________________________________
              </p>
              <p className="flex flex-col justify-end items-end pr-14">
                {template.signatureText}
              </p>
            </div>
          );
        })}
      </section>
    </div>
  );
}

export default TemplatesPage;
